import React from "react";
import { Link } from "react-router-dom";

function HeroSection() {
  return (
    <section className="relative isolate overflow-hidden bg-gradient-to-r from-gray-800 via-gray-900 to-gray-800 pt-20 lg:pt-32 pb-16">
      <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-24 items-center lg:grid-cols-12 lg:gap-32">
          {/* Text Section */}
          <div className="w-full xl:col-span-5 lg:col-span-6">
            <p className="text-base font-semibold leading-7 text-orange-500">
              #1 Medical Research Companion
            </p>
            <h1 className="mt-6 text-center lg:text-left font-extrabold text-4xl md:text-5xl lg:text-6xl leading-tight text-gray-100">
              <span className="bg-gradient-to-r from-orange-400 to-red-600 bg-clip-text text-transparent">
                Streamline Your Medical Research
              </span>
            </h1>
            <p className="mt-6 text-xl leading-8 text-gray-300 text-center lg:text-left">
              Unlock insights from over 26 million scientific articles. Start
              your journey for free and elevate your research to the next level.
            </p>
            <div className="flex flex-col md:flex-row items-center justify-center lg:justify-start gap-6 mt-10">
              <Link
                to="/signup"
                className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium rounded-lg group bg-gradient-to-br from-orange-500 to-red-500 group-hover:from-orange-400 group-hover:to-red-400 hover:text-white text-white focus:ring-4 focus:outline-none focus:ring-orange-500"
              >
                <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-gray-900 rounded-md group-hover:bg-opacity-0">
                  Get Started Free
                </span>
              </Link>
              <Link
                to="/pricing"
                className="relative inline-flex items-center justify-center p-0.5 overflow-hidden text-sm font-medium rounded-lg group bg-gradient-to-br from-orange-500 to-red-500 group-hover:from-orange-400 group-hover:to-red-400 hover:text-white text-white focus:ring-4 focus:outline-none focus:ring-orange-500"
              >
                <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-gray-900 rounded-md group-hover:bg-opacity-0">
                  Explore Our Plans
                </span>
              </Link>
            </div>
          </div>

          {/* Image Section */}
          <div className="w-full xl:col-span-7 lg:col-span-6 block">
            <div className="relative lg:ml-16">
              <img
                src="/cure-ss-home-new.jpeg"
                alt="Cure AI Dashboard"
                className="w-full h-auto rounded-xl shadow-xl ring-1 ring-gray-400/10 lg:relative lg:right-[-10%]"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
