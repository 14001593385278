import React from "react";

const SectionBreak = () => {
  return (
    <div className="relative py-8 bg-gradient-to-r from-gray-800 via-gray-900 to-gray-800 flex items-center justify-center">
      <hr className="w-2/3 h-0.5 mx-auto bg-gray-700 border-0 rounded-full" />
    </div>
  );
};

export default SectionBreak;
