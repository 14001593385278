import React, { useState, useEffect, Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./Header";
import HeroSection from "./HeroSection";
import { Analytics } from "@vercel/analytics/react";
import SectionBreak from "./SectionBreak";

const Pricing = lazy(() => import("./Pricing"));
const Footer = lazy(() => import("./Footer"));
const ContactUsForm = lazy(() => import("./ContactUs"));
const FeaturesSection = lazy(() => import("./FeaturesSection"));
const AppShowcase = lazy(() => import("./AppShowcase"));
const Signup = lazy(() => import("./Signup"));
const CallToActionSection = lazy(() => import("./CallToActionSection"));
const SelectPlan = lazy(() => import("./SelectPlan"));
const PrivacyPolicy = lazy(() => import("./PrivacyPolicy"));
const TermsOfUse = lazy(() => import("./TermsOfUse"));

function App() {
  const [isVisible, setIsVisible] = useState(true);

  const checkScrollBottom = () => {
    const buffer = 225;
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const body = document.body;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom >= docHeight - buffer) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollBottom);
    return () => window.removeEventListener("scroll", checkScrollBottom);
  }, []);

  const LoadingSpinner = () => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <div className="flex justify-center">
        <span className="circle animate-loader"></span>
        <span className="circle animate-loader animation-delay-200"></span>
        <span className="circle animate-loader animation-delay-400"></span>
      </div>
      <div
        className="loading-text"
        style={{
          color: "#f3f3f3",
          fontSize: "1.5em",
          marginTop: "20px",
          animation: "fadeInOut 3s ease-in-out infinite",
        }}
      >
        Loading...
      </div>
    </div>
  );

  return (
    <Router>
      <Analytics />
      <div className="App flex flex-col min-h-screen">
        <Header />
        <div className="ContentWrapper flex-grow">
          <Suspense fallback={<LoadingSpinner />}>
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <HeroSection />
                    <SectionBreak />
                    {/* <Divider /> */}
                    <AppShowcase />
                    {/* <Divider /> */}
                    <SectionBreak />

                    <FeaturesSection />
                    {/* <Divider /> */}
                    <SectionBreak />

                    <CallToActionSection />
                  </>
                }
              />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/contact" element={<ContactUsForm />} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/select-plan" element={<SelectPlan />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/terms" element={<TermsOfUse />} />
            </Routes>
          </Suspense>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
