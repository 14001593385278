import React, { useState, useRef, useEffect } from "react";
import logo from "./assets/orange-trans.png";
import { Link, useLocation } from "react-router-dom";

function Header() {
  const location = useLocation(); // Get current location
  const [menuVisible, setMenuVisible] = useState(false);
  const menuRef = useRef(null);

  // Close the dropdown menu when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuVisible(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  // Dynamic styles based on the current route
  const linkStyles = (path) => {
    return location.pathname === path
      ? "bg-orange-600 text-black rounded-md px-3 py-2 text-sm font-medium hover:bg-orange-500"
      : "text-orange-600 hover:bg-orange-600 hover:text-black rounded-md px-3 py-2 text-sm font-medium";
  };

  // Toggle mobile dropdown menu visibility
  const toggleMenu = () => setMenuVisible((prev) => !prev);

  // Close the dropdown after navigating to a new page
  const closeMenu = () => setMenuVisible(false);

  return (
    <header>
      <nav className="bg-gradient-to-r from-gray-800 via-gray-900 to-gray-800">
        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
          <div className="relative flex h-20 items-center justify-between">
            <div className="flex flex-1 items-center justify-start">
              <div className="flex-shrink-0">
                <Link to="/" onClick={closeMenu}>
                  <img
                    className="h-16 w-auto"
                    src={logo}
                    alt="Your Company Logo - Providing XYZ Services"
                  />
                </Link>
              </div>
              {/* Hamburger icon for mobile menu */}
              <button
                onClick={toggleMenu}
                className="ml-auto sm:hidden text-orange-600 hover:text-orange-500 focus:outline-none"
                aria-label="Toggle menu"
              >
                {/* Hamburger Icon */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-8 h-8"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                </svg>
              </button>
              {/* Desktop Navigation Links */}
              <div className="hidden ml-6 sm:flex sm:items-center space-x-4">
                <Link to="/" className={linkStyles("/")}>
                  Home
                </Link>
                <Link to="/contact" className={linkStyles("/contact")}>
                  Contact Us
                </Link>
                <Link to="/pricing" className={linkStyles("/pricing")}>
                  Pricing
                </Link>
              </div>
            </div>
            {/* "Go to app" Button - Desktop */}
            <div className="hidden sm:block">
              <a
                href="https://www.askcure.ai"
                target="_blank"
                rel="noopener noreferrer"
                className="px-4 py-2 text-sm font-medium text-white bg-orange-600 rounded-md hover:bg-orange-500"
                aria-label="Go to our application - Your Company App"
              >
                Go to app
              </a>
            </div>
          </div>
          {/* Mobile Dropdown Menu */}
          {menuVisible && (
            <div
              ref={menuRef}
              className="sm:hidden bg-gray-800 border-t border-gray-700 px-4 pt-2 pb-3 flex flex-col space-y-2 w-full"
            >
              <Link to="/" className={linkStyles("/")} onClick={closeMenu}>
                Home
              </Link>
              <Link
                to="/contact"
                className={linkStyles("/contact")}
                onClick={closeMenu}
              >
                Contact Us
              </Link>
              <Link
                to="/pricing"
                className={linkStyles("/pricing")}
                onClick={closeMenu}
              >
                Pricing
              </Link>
              <a
                href="https://www.askcure.ai"
                target="_blank"
                rel="noopener noreferrer"
                className="block px-4 py-2 text-sm font-medium text-white bg-orange-600 rounded-md hover:bg-orange-500"
                aria-label="Go to our application - Your Company App"
                onClick={closeMenu}
              >
                Go to app
              </a>
            </div>
          )}
        </div>
      </nav>
    </header>
  );
}

export default Header;
